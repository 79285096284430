/* itemLoading */
.itemLoading {
  width: calc(100% - 2em);
  margin-bottom: 1rem;
  padding-left: 4px;
  margin-right: 1em;
  border-radius: 2px;
  background: linear-gradient(110deg, #bfbfbf 8%, #ffffff 18%, #bfbfbf 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
  list-style: none;
}

.itemLoading p {
  color: #818181;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}