@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

@font-face {
    font-family: 'Futura LT Bold';
    src: url('./assets/fonts/FuturaLT-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura LT';
    src: url('./assets/fonts/FuturaLT-Book.ttf') format('truetype');
}

@font-face {
    font-family: 'Futura LT Heavy';
    src: url('./assets/fonts/FuturaLT-Heavy.ttf') format('truetype');
}

@font-face {
    font-family: 'Bebas Neue';
    src: url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype');
}

body {
    margin: 0px;
    font-family: "Futura LT" !important;
    overflow-x: hidden;
    font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Futura LT";
}

h1 {
    font-size: 40px;
    color: white;
    font-family: 'Bebas Neue';
}

h2 {
    font-size: 25px;
    color: black;
    font-family: 'Bebas Neue';
}

h3 {
    font-size: 18px;
    color: black;
}

h5 {
    font-size: 22px !important;
    color: black;
}

textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {
    border-color: black;
}

select:focus {
    border-color: black;
}

#root {
    display: grid;
}

.form-control {
    border-radius: 0 !important;
    border: 1px solid #000000;
    height: 38px !important;
    box-shadow: none !important;
    display: inline-flex;
    font-size: 14px;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: black !important;
}

.form-label {
    text-align: left;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.3px;
    color: #000000;
    margin-bottom: 1rem;
    display: block;
}

.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: transparent;
}

.custom-file-label {
    border: 0;
    cursor: pointer;
    justify-content: flex-start !important;
    cursor: pointer;
    border-radius: none !important;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: url('assets/icons/icon-upload-files.svg') !important;
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: transparent !important;
    border-left: none !important;
    border-radius: none !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #000;
    padding-right: calc(1.5em + .75rem);
    background-image: none;
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #000;
    box-shadow: none;
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #000;
    box-shadow: none;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #000 !important;
}

.custom-control-input.is-valid~.custom-control-label::before,
.was-validated .custom-control-input:valid~.custom-control-label::before {
    border-color: #000;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #000;
    padding-right: calc(1.5em + .75rem);
    background-image: none;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    box-shadow: none !important;
}

.was-validated select.form-control:valid {
    padding-right: 1.5em !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none !important;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-primary {
    background-color: #000000 !important;
    color: #ffffff !important;
    border: 1px solid #000000 !important;
    border-radius: 0 !important;
    max-width: 230px;
    font-size: 14px;
}

.btn-white {
    background-color: #FFF !important;
    border: 1px solid #000000 !important;
    border-radius: 0;
    color: #000 !important;
    font-size: 14px;
}

.btn-white:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

textarea.form-control {
    height: 123px !important;
    width: 100%;
    border: 1px solid #000;
    border-radius: 0;
}


/*LOGIN*/

.login-form {
    display: grid;
    align-items: center;
    max-width: 67%;
    width: 67%;
    margin: 10% auto;
}

.input-form {
    margin-bottom: 50px;
    max-width: 100%;
    width: 100%;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
    background-color: transparent;
    color: #ffffff;
}

.input-form::placeholder {
    color: #ffffff;
    font-weight: lighter !important;
    opacity: .8;
}

.black_and_white-theme {
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #FFFFFF;
    opacity: 1;
    color: #FFFFFF;
    height: 51px;
    margin-top: 60px;
}

.black_and_white-theme:hover {
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #000000;
}

.black_and_white-theme.disabled {
    opacity: 0.3;
}

.small-input {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    height: 40px;
}

.large-input {
    max-width: 100%;
    width: 100%;
}


/* HOME*/

.home-hr {
    width: 40px;
    margin: 10px 0 0 0;
    background-color: #fff;
    height: 3px;
}

.home-logo {
    width: 250px;
    max-width: 350px;
}

.current-page {
    border-radius: 0;
    border-bottom: 1px solid #000;
}


/* AMENIDADES*/

.btn-amenities-type {
    border: 1px solid #000000;
    border-radius: 0;
    line-height: 16px;
}


/* TARJETAS */

.custom-card {
    border-radius: 20px;
    border: 1px solid #000;
    box-shadow: 0 0 5px #555;
    background-color: white;
}

.custom-card-header {
    height: 55px;
    border-radius: 20px 20px 0px 0px;
    background-color: #ccc;
    border-bottom: 1px solid #000;
}

.custom-card-header h4 {
    font-size: 20px;
    color: black;
    font-family: 'Bebas Neue';
    font-weight: normal;
}

.custom-card-header .text-noWrapNoMargin {
    margin: 0;
}

.custom-card-body {
    padding: 20px 0px 15px 0px;
}

.custom-card-body.custom-card-body-files {
    height: calc(100% - 55px);
}


/* TABLAS*/

.p-datatable {
    min-height: 40vh;
}

.p-datatable-tbody td {
    border: 0 !important;
    font-size: 12px;
    padding: 0.5rem !important;
    text-align: center;
    background: transparent !important;
}

.p-datatable-thead th {
    border: 0 !important;
    background: transparent !important;
    font-size: 14px;
    font-weight: bold;
    border-bottom: 1px #A8A8A8 solid !important;
}

.p-datatable-no-border .p-datatable-thead th {
    border-bottom: 0 !important;
}

.p-component {
    font-family: "Futura LT" !important;
}

.table-no-border th,
.table-no-border td {
    border: 0;
    padding: 10px 10px;
}

.table-scroll-y-scrollable {
    height: calc(100% - 150px);
    overflow: auto;
}

.table-responsive th {
    vertical-align: middle !important;
}

.table-scroll-y {
    width: 100%;
}

.table-row-editable {
    overflow-x: auto;
    overflow-y: visible;
    height: auto;
}

.table-row-editable.expanded {
    padding-bottom: 180px;
}

.table-row-editable.expanded>table {
    width: 150%;
}

table td b {
    font-size: 14px;
}

.image-label {
    text-align: center;
}

.table-row-border-top {
    border-top: 1px solid #888;
}

.table-row-border-bottom {
    border-bottom: 1px solid #888;
}

.column-gray {
    background-color: #BEBEBE42;
}

.column-border {
    border-left: 1px solid #ccc !important;
    border-right: 1px solid #ccc !important;
}

.column-border-left {
    border-left: 1px solid #ccc !important;
}

.column-border-right {
    border-right: 1px solid #ccc !important;
}

.table-striped tbody tr:nth-of-type(odd),
.p-datatable-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}

.table-striped tbody tr:nth-of-type(even),
.p-datatable-striped tbody tr:nth-of-type(even) {
    background-color: #f3f3f3 !important;
}

.table-striped tbody tr.invalid-unit:nth-of-type(even),
.p-datatable-striped tbody tr.invalid-unit:nth-of-type(even) {
    background-color: #dc3545 !important;
}

tr.invalid-unit {
    background-color: #dc3545 !important;
}

.table thead th {
    border-top: none;
}

table th {
    border-top: none;
    font-size: 14px;
}

table td {
    font-size: 12px;
}

table td input.form-control,
table td .input-table-td {
    height: 26px !important;
    font-size: 12px;
}

table td .input-table-td .dropdown__control,
table td .input-table-td .dropdown__value-container table td .dropdown__single-value,
table td .input-table-td .dropdown__control .dropdown__indicators,
table td .input-table-td .dropdown__control--is-focused,
table td .input-table-td .dropdown__control .dropdown__value-container,
table td .input-table-td .dropdown__control--is-focused .dropdown__value-container,
table td .input-table-td .dropdown__control--is-focused .dropdown__indicators {
    height: 26px !important;
    min-height: auto;
}

table td .input-group-prepend,
table td .input-group-append {
    height: 26px !important;
    font-size: 12px;
}

table td .input-group-prepend .input-group-text,
table td .input-group-append .input-group-text {
    padding: 5px;
    font-size: 12px;
}

table td .p-dropdown,
table td select.form-control {
    width: 100%;
    height: 26px !important;
    font-size: 12px !important;
    padding: 0;
}

table td .p-dropdown .p-dropdown-panel {
    font-size: 12px;
}

table td .p-dropdown .p-dropdown-label {
    padding: 2px 5px;
    font-size: 12px !important;
}

table td .p-dropdown .p-dropdown .p-dropdown-trigger {
    width: 20px;
}

table td .p-dropdown .p-dropdown-trigger-icon {
    padding: 0px;
    font-size: 12px !important;
}


/* GENERALES*/

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
}

.label-elipsis label {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.icon-image {
    width: 20px;
}

.icon-edit {
    width: 20px;
}

.upload-image-sm {
    height: 26px;
    width: 20px;
    background-size: 100% 100%;
}

.large {
    width: 100%;
}

.medium {
    width: 50%;
}

.small {
    width: 25%;
}

.pointer {
    cursor: pointer;
}

.btn-plus {
    font-size: 13px;
    cursor: pointer;
}

.btn-plus.disabled {
    cursor: default;
    color: #aaa;
}

.btn-icon {
    display: inline-block;
    cursor: pointer;
}

.w-100px {
    min-width: 100px;
}

.w-50px {
    width: 50px !important;
}

.with-message {
    position: relative;
    padding-bottom: 30px;
}

.with-message .error {
    color: #dc3545;
    font-size: 80%;
    margin: 0;
    position: absolute;
    bottom: 5px;
    left: 0;
}

.adjuntos .with-message .error {
    margin-left: 13px;
}

.error-red {
    color: #dc3545;
    font-size: 80%;
}

.color-red {
    color: red;
}

.no-border {
    border: 0 !important;
}

.loading-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1070;
    padding-top: calc(50vh - 2em);
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
}

.icon-delete {
    width: 30px;
    height: 30px;
    padding-right: 2px;
}

.break-word {
    word-break: break-all;
}

.btn-submenu {
    height: 38px;
    width: 38px;
    border: 1px solid #000 !important;
    border-radius: 0 !important;
    padding: 0 !important;
}

.btn-submenu.active-tab {
    background-color: #000 !important;
}

.btn-submenu.disabled {
    opacity: 0.5;
}

.btn-submenu img.active {
    display: none;
}

.btn-submenu.active-tab img.active {
    display: inline;
}

.btn-submenu.active-tab img.no-active {
    display: none;
}

.form-radio-reparator {
    border-right: 1px #888 solid;
}

.container-message-alert {
    position: fixed;
    z-index: 2000;
    width: 1px;
    height: 1px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 280px;
    right: 0;
}

.message-alert {
    width: 280px;
    height: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    position: absolute;
    right: 0;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    box-shadow: 1px 1px 5px #555;
}

.error-border {
    box-shadow: 0px 0px 3px red !important;
}

.modal-header {
    border-bottom: 0;
}

.dropdown-color-1 {
    background-color: #46D237;
}

.dropdown-color-2 {
    background-color: #FF4343;
}

.dropdown-color-3 {
    background-color: #FFFF00;
}

.dropdown-color-4 {
    background-color: #A7A7A7;
}

.dropdown-color-rent-1 {
    background-color: #88EC7D;
}

.dropdown-color-rent-2 {
    background-color: #F28080;
}

.dropdown-color-rent-3 {
    background-color: #EED677;
}

.dropdown-color-rent-4 {
    background-color: #A7A7A7;
}

.btn-go-up,
.btn-go-up:focus {
    border: 1px solid #000;
    width: 30px;
    height: 30px;
    position: fixed;
    bottom: 60px;
    background: #fff;
    margin: auto;
    /*left: 950px;*/
    right: 0;
    outline: 0;
    border-radius: 0;
}

.font-size-13 {
    font-size: 13px;
}

.overflow-x-auto {
    overflow-x: auto;
}

.images-dropzone .dropzone {
    display: inline-block;
}

.images-dropzone .dropzone-label {
    display: block;
    text-align: center !important;
}

.images-dropzone-img {
    position: relative;
}

.images-dropzone-img .img-trash-icon {
    position: absolute;
    top: 10px;
    left: 230px;
    right: 0;
    margin: auto;
}

.slash {
    width: 10px;
    height: 1px;
    border-top: 2px solid #000;
    display: block;
    margin: auto;
}


.input-height-30 input.form-control,
.input-height-30 .input-group-prepend {
    height: 30px !important;
    font-size: 12px;
}

@media only screen and (min-width: 1281px) {
    .container {
        max-width: 1280px;
    }

    .btn-go-up {
        /*left: 1350px;*/
    }

    .modal-models {
        max-width: 80% !important;
        width: 80% !important;
    }

    .modal-models .form-group {
        padding-right: 25%;
    }

    .modal-models .col-12 .form-group {
        padding-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .home-logo {
        width: 80%;
    }

    .section-list {
        background-position: center 180px;
        min-width: 1280px;
    }

    .loading-container {
        height: 100% !important;
    }

    .modal-backdrop {
        width: 100%;
        height: 100%;
    }

    .modal-addFiles,
    .modal-models {
        width: 100% !important;
        max-width: 100% !important;
    }

    .modal-logout {
        display: block;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        min-height: 100px;
        text-align: center;
    }

    .modal-logout .btn-primary {
        max-width: 100%;
    }

    .modal-logout .border-image {
        width: 80px;
        margin: auto;
    }
}

.p-tooltip-active {
    z-index: 1051 !important;
}

/* levantamientos */

.table-levantamientos {
    height: 500px;
    display: block;
    overflow-y: auto;
    overflow-x: scroll;
}

.table-levantamientos thead tr th {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #ffffff;
}

.table-levantamientos thead tr th,
.table-levantamientos tbody tr td {
    min-width: 150px;

}

.table-levantamientos thead tr th,
.table-levantamientos tbody tr td {
    min-width: 150px;

}

.table-levantamientos tbody tr .fixed1,
.table-levantamientos tbody tr .fixed2,
.table-levantamientos tbody tr .fixed3 {
    background-color: #ffffff !important;
}

.table-levantamientos tbody tr:nth-child(2n) .fixed1,
.table-levantamientos tbody tr:nth-child(2n) .fixed2,
.table-levantamientos tbody tr:nth-child(2n) .fixed3 {
    background-color: #f3f3f3 !important;
}

.fixedth1,
.fixedth2,
.fixedth3 {
    z-index: 11 !important;
}

.fixed1,
.fixed2,
.fixed3 {
    z-index: 1 !important;
}

.fixed1,
.fixedth1 {
    position: sticky;
    left: 0;
    min-width: 70px !important;
}

.fixed2,
.fixedth2 {
    position: sticky;
    left: 70px;
    min-width: 70px !important;
}

.fixed3,
.fixedth3 {
    position: sticky;
    left: 140px;
    min-width: 70px !important;
}

.w-300 {
    min-width: 300px !important;
}

.selectBiss__value-container,
.selectBiss__indicators {

    height: 25px;
}

.is-cursor {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

/* Estilos nuevos sobre select multiple */
#root>section>div.container.bg-white.px-4.pb-5>div:nth-child(4)>form>div.custom-card-body.px-3>div:nth-child(2)>div:nth-child(1)>div>div>div {
    height: auto;
}

/* Estilos de filtros control Form */
@media screen and (max-width: 1656px) {
    body>div.fade.px-4.modal.show>div>div>div.mx-3.modal-body>div.custom-card.mt-4>div.custom-card-header.d-flex.justify-content-between.align-items-center>div {
        justify-content: flex-start;
    }

    body>div.fade.px-4.modal.show>div>div>div.mx-3.modal-body>div.custom-card.mt-4>div.custom-card-header.d-flex.justify-content-between.align-items-center>div>h4 {
        white-space: break-spaces !important;
        text-align: right;
    }
}