button#buttonDownloadFile {
  border: 9px;
  border-radius: 10px;
  position: absolute;
  right: 1em;
  outline: none;
}

button#buttonDownloadFile:hover {
  color: #007bff;
}

button#buttonDownloadFile:hover:disabled {
  color: #9b9a9a;
}

button#buttonDownloadFile svg {
  margin: 0 !important;
}

.breakWord {
  overflow-wrap: break-word !important;
}